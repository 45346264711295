import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/Home.js";
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import AmazonSpend from "./pages/AmazonSpend";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/amzSpending" element={<AmazonSpend/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
