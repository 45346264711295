import React from "react";
import "./Landing.css";
import { Button, Box } from "@mui/material";
import LinkedInIcon from "../assets/linkedInIcon.png";
import GmailIcon from "../assets/gmailIcon.png";
import { padding } from "@mui/system";

function Landing() {
  return (
    <div className="landing-container">
      <div className="landing-content">
        <text className="h1-landing" id="h1-landing">
          Hello, I'm
        </text>
        <h2>Navin Ravindran</h2>

        <p
          style={{
            fontFamily: "Prompt",
            color: "#ffffff",
            fontWeight: "900",
            fontSize: "22px",
          }}
        >
        working on this page...nothin here.
        </p>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#939393", color: "black", margin: "5%" }}
          href=""
        >
          <span style={{ fontWeight: "800" }}>Resume</span>
        </Button>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            paddingX: "20vw",
            marginTop: "10vh",
          }}
        >
          <a href="https://www.linkedin.com/in/navinravindran/">
            {" "}
            <img src={LinkedInIcon} height="45px" width="45px"></img>
          </a>
          <h6 id="connect-text">Connect with me on</h6>
          <a href="mailto:navinanbumech@gmail.com">
            {" "}
            <img src={GmailIcon} height="50px" width="50px"></img>
          </a>
        </Box>
      </div>
    </div>
  );
}

export default Landing;
