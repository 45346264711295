import React from "react";
import ResponsiveAppBar from "../appBar/MuiAppBar";
import Landing from "../../pages/Landing";

function Home() {
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Landing/>
    </>
  );
}

export default Home;
